<template>
    <section
        class="hero main-landing"
        :class="{ 'header--scrolled': headerScrolled }"
    >
        <div class="scroll-div" id="hero"></div>
        <div class="hero__wrapper">
            <div class="hero__left">
                <div class="hero__left-content">
                    <h1 class="hero__title">
                        Tutaj
                        <br />
                        umówisz się
                        <br />
                        na randkę
                    </h1>
                    <p class="hero__subtitle">
                        <!-- Nowe, wysokie standardy<br> randkowania -->
                    </p>
                    <SimpleButton
                        :text="`Zapisz się`"
                        :route="`${
                            isLoggedIn ? '/panel/notification' : '/register'
                        }`"
                        class="hero__button"
                    />
                </div>
                <!-- <img
          src="@/assets/images/baner-left.jpg"
          class="hero__img hero__img-left"
        /> -->
            </div>
            <div class="hero__right">
                <img
                    src="@/assets/images/Basia-portret.png"
                    class="hero__img"
                    alt="Basia Strójwąs"
                />
            </div>
        </div>
    </section>
</template>
<script>
    import SimpleButton from '@/components/landing-03/atoms/SimpleButton.vue'

    export default {
        data() {
            return {
                headerScrolled: false
            }
        },
        mounted() {
            this.headerScroll()
        },
        methods: {
            headerScroll() {
                document.addEventListener('DOMContentLoaded', () => {
                    const heroSection = document.querySelector('.main-landing')
                    document.addEventListener('scroll', () => {
                        heroSection.getBoundingClientRect().top < 0
                            ? (this.headerScrolled = true)
                            : (this.headerScrolled = false)
                    })
                })
            }
        },
        computed: {
            isLoggedIn() {
                return localStorage.getItem('userId') ? true : false
            }
        },
        components: {
            SimpleButton
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/breakpoints.scss';
    @import '@/assets/scss/containers.scss';
    @import '@/assets/scss/Nunito.css';
    @import '@/assets/scss/global.scss';

    .header--scrolled {
        // padding-top: 92px !important;
    }
    .hero {
        overflow: hidden;
        // border-top: 1px solid $black;
        border-bottom: 1px solid $black;
        background-color: $begeNew;
        padding-top: 119px;
        transition: 0.3s;
        @include breakpoint-max('xs-tablet') {
            padding-top: 50px;
        }
        // &__container {
        //     width: 100%;
        //     max-width: calc((100% - ((100% - 1681px) / 2)) + 30px);
        //     margin-left: auto;
        //     padding-left: 30px;

        //     @include breakpoint-max('xs-tablet') {
        //         padding: 0;
        //     }
        //     // @include breakpoint-max("huge") {
        //     // width: 100%;
        //     // padding-right: 30px;
        //     // margin-right: 0;
        //     // }
        // }
        &__wrapper {
            display: flex;
            height: 100vh;
            min-height: 600px;
            @include breakpoint-max('tablet') {
                flex-direction: column;
                height: 130vh;
            }
        }
        &__left {
            flex: 1;
            display: flex;
            justify-content: center;
            flex-direction: column;
            column-gap: 20px;
            position: relative;
            background-color: #e1dbd5;
            border-right: 1px solid black;
            @include breakpoint-max('tablet') {
                padding: 30px;
                max-width: 100%;
                flex: 1;
                border-right: none;
            }
        }
        &__left-content {
            margin-left: 10%;
            position: relative;
            z-index: 2;
            color: $black3;
            padding-right: 61px;
            @include breakpoint-max('m-large') {
                margin-left: 30px;
            }
            @include breakpoint-max('tablet') {
                margin-left: 0;
            }
            @include breakpoint-max('tablet') {
                padding-right: 0;
            }
        }

        &__right {
            flex: 1;
            // max-width: 50%;
            position: relative;
            img {
                height: 100.5%;
            }
            @include breakpoint-max('tablet') {
                border: none;
                border-top: 1px solid $black;
                max-width: 100%;
                flex: 1;
            }
        }
        &__img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: left;
            @include breakpoint-max('tablet') {
                object-position: left 20%;
            }
        }
        &__img-left {
            object-position: right;
            z-index: 1;
            height: 100.5% !important;
            @include breakpoint-max('tablet') {
                object-position: left;
            }
        }
        &__title {
            font-size: 11rem;
            line-height: 12.5rem;
            font-family: 'Oswald', sans-serif;
            font-weight: 800;
            margin-bottom: 8%;
            letter-spacing: 0;
            @include breakpoint-max('v-large') {
                font-size: 9.5rem;
                line-height: 10.7rem;
            }
            @include breakpoint-max('m-large') {
                font-size: 10rem;
                line-height: 11.7rem;
            }
            @include breakpoint-max('large') {
                font-size: 9.7rem;
                line-height: 10.7rem;
            }
            @include breakpoint-max('xl-tablet') {
                font-size: 8.5rem;
                line-height: 10.7rem;
            }
            @include breakpoint-max('tablet') {
                font-size: 15.5rem;
                line-height: 17.7rem;
            }
            @include breakpoint-max('s-tablet') {
                font-size: 13.5rem;
                line-height: 14.7rem;
            }
            @include breakpoint-max('xs-tablet') {
                font-size: 10.5rem;
                line-height: 12.7rem;
            }
            @include breakpoint-max('mobile') {
                font-size: 75px;
                line-height: 86px;
            }
            @media only screen and (max-width: 550px) {
            }
            @media only screen and (max-width: 500px) {
                font-size: 65px;
                line-height: 76px;
            }
            @media only screen and (max-width: 450px) {
                font-size: 57px;
                line-height: 70px;
            }
            @media only screen and (max-width: 400px) {
                font-size: 47px;
                line-height: 58px;
            }
            @media only screen and (max-width: 350px) {
                font-size: 41px;
                line-height: 49px;
            }
        }
        &__subtitle {
            font-size: 3.5rem;
            line-height: 5.2rem;
            font-family: 'Promenade', sans-serif;
            font-weight: 300;
            margin-bottom: 8%;
            text-transform: uppercase;
            @include breakpoint-max('tablet') {
                font-size: 28px;
                line-height: 35px;
            }
            @include breakpoint-max('mobile') {
                font-size: 24px;
                line-height: 32px;
            }
        }
    }
</style>

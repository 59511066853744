<template>
    <div class="reinstate-user-acc">
        <div class="reinstate-user-acc__header">
            <h1 class="text-4xl font-medium">
                Przywróć wcześniej zawieszone konto
            </h1>
        </div>
        <div class="reinstate-user-acc__form">
            <form @submit.prevent="handleReinstateAccount">
                <label for="userEmail">
                    <input
                        placeholder="Email użytkownika"
                        email="userEmail"
                        type="text"
                        v-model="userEmail"
                        class="login__form-userEmail"
                    />
                </label>

                <div class="reinstate-user-acc__form-response">
                    <div
                        class="reinstate-user-acc__form-response__html"
                        v-html="response"
                    ></div>
                </div>
                <div class="reinstate-user-acc__form-bottom">
                    <button v-if="!pending">Wyślij</button>
                    <button disabled v-else class="loader">
                        <svg viewBox="25 25 50 50">
                            <circle r="20" cy="50" cx="50"></circle>
                        </svg>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue'
    import axios from 'axios'
    const userEmail = ref('')
    const response = ref('')
    const pending = ref(false)
    const API_URL = process.env.VUE_APP_API

    const getCookie = name => {
        const value = `; ${document.cookie}`
        const parts = value.split(`; ${name}=`)
        if (parts.length === 2) return parts.pop().split(';').shift()
    }

    const handleReinstateAccount = async () => {
        const authToken = getCookie('authToken')
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken}`
            }
        }

        pending.value = true
        response.value = ''
        try {
            const res = await axios.post(
                `${API_URL}api/users/reinstate-account-after-temporary-disabled`,
                {
                    email: userEmail.value
                },

                config
            )
            response.value = res?.data?.message
        } catch (error) {
            console.error(error)
            response.value = error?.message
        } finally {
            pending.value = false

            // setTimeout(() => {
            //     response.value = ''
            //     userEmail.value = ''
            // }, 10000)
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/breakpoints.scss';
    @import '@/assets/scss/containers.scss';
    @import '@/assets/scss/global.scss';

    .reinstate-user-acc {
        &__header {
            margin-bottom: 20px;
        }
        &__form {
            display: flex;
            flex-direction: column;
            max-width: 1075px;
            padding-top: 40px;

            input {
                padding: 27px 25px;
                color: $black;
                font-size: 16px;
                line-height: 30px;
                border-radius: 0;
                font-family: 'Open Sans', sans-serif;
                outline: none;
                border: 1px solid $black;
                background-color: transparent;
                width: 100%;
                @include breakpoint-max('xs-tablet') {
                    padding: 17px 15px;
                }
                @include breakpoint-max('mobile') {
                    padding-top: 53px;
                }

                &::placeholder {
                    color: $black;
                    font-size: 16px;
                    font-weight: 700;
                }
            }

            button {
                outline: none;
                font-size: 2.4rem;
                background-color: $pink;
                line-height: 3rem;
                color: $white;
                text-transform: uppercase;
                font-family: 'Open Sans', sans-serif;
                font-weight: 700;
                padding: 10px 40px;
                margin-top: 20px;
                cursor: pointer;
                opacity: 1;
                transition: all 0.3s ease-in;
                width: 180px;

                &:hover {
                    opacity: 0.7;
                }
            }
            label {
                display: block;
                position: relative;
                p {
                    font-weight: 700;
                    color: $black;
                    font-size: 16px;
                    line-height: 30px;
                    font-family: 'Open Sans', sans-serif;
                    position: absolute;
                    top: 51%;
                    transform: translateY(-50%);
                    left: 27px;
                    @include breakpoint-max('mobile') {
                        top: 20px;
                        transform: translateY(0%);
                        left: 28px;
                    }
                }

                #userEmail {
                    padding-left: 130px;
                }
            }

            &-response {
                margin-top: 10px;
                font-size: 16px;

                &__html {
                    * {
                        padding: 5px 0px !important;
                    }
                }
            }
        }
    }
</style>

<template>
    <div v-if="isMaintenanceMode" class="maintenance-alert">
        <span class="maintenance-message">
            <i class="fas fa-wrench"></i>
            Trwa przerwa techniczna w godzinach 10:00 - 16:00. Przepraszamy za
            utrudnienia.
        </span>
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import axios from 'axios'

    export default {
        name: 'MaintenanceAlert',
        setup() {
            const isMaintenanceMode = ref(false)

            const checkMaintenanceMode = async () => {
                try {
                    const response = await axios.get(
                        `${process.env.VUE_APP_API}api/settings/is-maintenance-mode`
                    )

                    isMaintenanceMode.value = response.data
                } catch (error) {
                    console.error('Error checking maintenance mode:', error)
                }
            }

            onMounted(() => {
                checkMaintenanceMode()
                // Check every 5 minutes
                setInterval(checkMaintenanceMode, 5 * 60 * 1000)
            })

            return {
                isMaintenanceMode
            }
        }
    }
</script>

<style scoped>
    .maintenance-alert {
        background-color: #d13724;
        color: #fff;
        padding: 2rem;
        text-align: center;
        width: 100%;
        z-index: 1000;
    }

    .maintenance-message {
        font-size: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        font-weight: 700;
    }

    .maintenance-message i {
        font-size: 2rem;
    }
</style>

<template>
    <div class="delete-user-acc">
        <div class="delete-user-acc__header">
            <h1 class="text-4xl font-medium">Symulacja dopasowania</h1>
        </div>
        <div class="delete-user-acc__form">
            <form @submit.prevent="handleDeleteAccount">
                <label for="userID">
                    <input
                        placeholder="ID użytkownika"
                        id="userID"
                        type="text"
                        v-model="userID"
                        class="login__form-userID"
                    />
                </label>

                <div class="delete-user-acc__form-response">
                    <div v-if="report" class="report">
                        <h2>
                            Raport matchowania dla użytkownika:
                            {{ report.userId }}
                        </h2>

                        <h3>Podsumowanie</h3>
                        <ul>
                            <li>
                                Początkowo znalezionych dopasowań:
                                {{ report.initialMatches.length }}
                            </li>
                            <li>
                                Dopasowania po filtrowaniu:
                                {{ getFilteredMatchesCount() }}
                            </li>
                        </ul>

                        <h3>Szczegółowy raport etapów</h3>
                        <div
                            v-for="(stage, index) in report.stagesReport"
                            :key="index"
                            class="stage-report"
                        >
                            <template v-if="stage.stage">
                                <h4>{{ stage.stage }}</h4>
                                <p>{{ stage.description }}</p>
                            </template>
                            <template v-else>
                                <h4>Kandydat ID: {{ stage.candidateId }}</h4>
                                <ul>
                                    <li
                                        v-for="(
                                            reason, reasonIndex
                                        ) in stage.stages"
                                        :key="reasonIndex"
                                    >
                                        {{ reason }}
                                    </li>
                                </ul>
                            </template>
                        </div>

                        <h3>Wynik końcowy</h3>
                        <p v-if="report.finalMatch">
                            Znaleziono match dla użytkownika:
                            {{ report.finalMatch }}
                        </p>
                        <p v-else>Nie znaleziono matcha dla użytkownika</p>
                    </div>
                </div>
                <div class="delete-user-acc__form-bottom">
                    <button v-if="!pending">Sprawdź</button>
                    <button disabled v-else class="loader">
                        <svg viewBox="25 25 50 50">
                            <circle r="20" cy="50" cx="50"></circle>
                        </svg>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue'
    import axios from 'axios'

    const userID = ref('')
    const response = ref('')
    const report = ref(null)
    const pending = ref(false)
    const API_URL = process.env.VUE_APP_API

    const getCookie = name => {
        const value = `; ${document.cookie}`
        const parts = value.split(`; ${name}=`)
        if (parts.length === 2) return parts.pop().split(';').shift()
    }

    const getFilteredMatchesCount = () => {
        if (report.value && report.value.stagesReport) {
            const heightFilteringStage = report.value.stagesReport.find(
                stage => stage.stage === 'Height Filtering'
            )
            return heightFilteringStage
                ? heightFilteringStage.matchesCount
                : 'N/A'
        }
        return 'N/A'
    }

    const handleDeleteAccount = async () => {
        const authToken = getCookie('authToken')
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken}`
            }
        }

        pending.value = true
        response.value = ''
        report.value = null
        try {
            const res = await axios.post(
                `${API_URL}api/users/get-simulates/${userID.value}`,
                {
                    adminId: localStorage.getItem('userId')
                },
                config
            )
            response.value = res?.data?.message
            report.value = res?.data?.report
        } catch (error) {
            console.error(error)
            response.value = error?.message
        } finally {
            pending.value = false
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/breakpoints.scss';
    @import '@/assets/scss/containers.scss';
    @import '@/assets/scss/global.scss';

    .delete-user-acc {
        &__header {
            margin-bottom: 20px;
        }
        &__form {
            display: flex;
            flex-direction: column;
            max-width: 1075px;
            padding-top: 40px;

            input {
                padding: 27px 25px;
                color: $black;
                font-size: 16px;
                line-height: 30px;
                border-radius: 0;
                font-family: 'Open Sans', sans-serif;
                outline: none;
                border: 1px solid $black;
                background-color: transparent;
                width: 100%;
                @include breakpoint-max('xs-tablet') {
                    padding: 17px 15px;
                }
                @include breakpoint-max('mobile') {
                    padding-top: 53px;
                }

                &::placeholder {
                    color: $black;
                    font-size: 16px;
                    font-weight: 700;
                }
            }

            button {
                outline: none;
                font-size: 2.4rem;
                background-color: $pink;
                line-height: 3rem;
                color: $white;
                text-transform: uppercase;
                font-family: 'Open Sans', sans-serif;
                font-weight: 700;
                padding: 10px 40px;
                margin-top: 20px;
                cursor: pointer;
                opacity: 1;
                transition: all 0.3s ease-in;
                width: 180px;

                &:hover {
                    opacity: 0.7;
                }
            }
            label {
                display: block;
                position: relative;
                p {
                    font-weight: 700;
                    color: $black;
                    font-size: 16px;
                    line-height: 30px;
                    font-family: 'Open Sans', sans-serif;
                    position: absolute;
                    top: 51%;
                    transform: translateY(-50%);
                    left: 27px;
                    @include breakpoint-max('mobile') {
                        top: 20px;
                        transform: translateY(0%);
                        left: 28px;
                    }
                }

                #userEmail {
                    padding-left: 130px;
                }
            }

            &-response {
                p {
                    font-size: 1.6rem;
                    color: $black;
                    margin-top: 20px;
                }
            }
        }
    }
</style>

import { defineStore } from 'pinia'
import axios from 'axios'

export const useSettingsStore = defineStore('settings', {
    state: () => ({
        connectedToProductionDB: null
    }),
    actions: {
        getCookie(name) {
            const value = `; ${document.cookie}`
            const parts = value.split(`; ${name}=`)
            if (parts.length === 2) {
                return parts.pop().split(';').shift()
            }
        },
        async checkDBMode() {
            try {
                const authToken = this.getCookie('authToken')

                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                const response = await axios.get(
                    `${process.env.VUE_APP_API}api/settings/prod-db-connection`,
                    config
                )

                this.connectedToProductionDB = response?.data
            } catch (error) {
                console.error(error)
            }
        }
    }
})

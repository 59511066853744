import { defineStore } from 'pinia'
import axios from 'axios'

export const useUserStore = defineStore('user', {
    state: () => ({
        user: null,
        userAnnouncements: null,
        questionnaire: null
    }),
    actions: {
        getCookie(name) {
            const value = `; ${document.cookie}`
            const parts = value.split(`; ${name}=`)
            if (parts.length === 2) {
                return parts.pop().split(';').shift()
            }
        },
        async getUser() {
            try {
                const authToken = this.getCookie('authToken')

                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                const userID = localStorage.getItem('userId')

                const response = await axios.get(
                    `${process.env.VUE_APP_API}api/users/userData/${userID}`,
                    config
                )

                this.user = response?.data
            } catch (error) {
                console.error(error)
                console.log(`[user store] error setting user`)
            }
        },
        async getUserAnnouncements() {
            console.log(`[🍍user store] -> getUserAnnouncements`)
            const authToken = this.getCookie('authToken')

            const config = {
                headers: { Authorization: `Bearer ${authToken}` }
            }

            const userID = localStorage.getItem('userId')

            try {
                const response = await axios.get(
                    `${process.env.VUE_APP_API}api/announcement/single-user/${userID}`,
                    config
                )

                this.userAnnouncements = response?.data
            } catch (error) {
                console.error(error)
                console.log(`[user store] error setting user announcements`)
            }
        },
        async getUserQuestionnaire() {
            try {
                const authToken = this.getCookie('authToken')

                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                const userID = localStorage.getItem('userId')

                const response = await axios.get(
                    `${process.env.VUE_APP_API}api/users/userData/questionnaire/${userID}`,
                    config
                )

                this.questionnaire = response.data
            } catch (error) {
                console.error(error)
                console.log(`[user store] error setting user questionnaire`)
            }
        },
        updateQuestionnaire(newData) {
            this.questionnaire = { ...this.questionnaire, ...newData }
        },
        async updateOnePhoto(photo, index) {
            try {
                const authToken = this.getCookie('authToken')

                const config = {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }

                const formData = new FormData()

                formData.append('userid', this.user._id)
                formData.append('photoIndex', index)
                formData.append(`file[${index}]`, photo)

                const response = await axios.post(
                    `${process.env.VUE_APP_API}api/edit-user/photo`,
                    formData,
                    config
                )

                console.log(response)
                console.log(response.data?.user)
            } catch (e) {
                console.log(e)
            }
        },
        async updateUserPhoto(photo, index, deletePhoto = false) {
            try {
                if (deletePhoto) {
                    const imgPlaceholder = require('@/assets/images/ludzik1.png')
                    this.user[`photo${index}`] = imgPlaceholder
                    return
                }

                this.user[`photo${index}`] = URL.createObjectURL(photo)
            } catch (e) {
                console.log(e)
            }
        },
        async closeAnnouncement(announcementId) {
            console.log(
                `[🍍user store] -> closeAnnouncement (id: ${announcementId})`
            )
            const authToken = this.getCookie('authToken')
            const config = {
                headers: { Authorization: `Bearer ${authToken}` }
            }

            try {
                axios
                    .put(
                        `${process.env.VUE_APP_API}api/announcement/close/${announcementId}`,
                        { userId: this.user._id },
                        config
                    )
                    .then(resp => {
                        const updatedAnnouncement = resp.data.announcement
                        const index = this.userAnnouncements.findIndex(
                            announcement => announcement._id === announcementId
                        )
                        if (index !== -1) {
                            this.userAnnouncements.splice(
                                index,
                                1,
                                updatedAnnouncement
                            )
                        }
                    })

                    .catch(err => {
                        console.log(err)
                    })
            } catch (error) {
                console.log(error)
            }
        },
        async sentNotifyAboutClickedInActivatePlusButton() {
            console.log(
                `[🍍user store] -> sentNotifyAboutClickedInActivatePlusButton`
            )
        }
    },
    getters: {
        lastPaymentBasicStatus: state => {
            return state.user?.paymentBasicHistory[
                state.user.paymentBasicHistory.length - 1
            ]?.status
        },
        lastPaymentPlusStatus: state => {
            return state.user?.paymentPlusHistory[
                state.user.paymentPlusHistory.length - 1
            ]?.status
        }
    }
})

import { createRouter, createWebHistory } from 'vue-router'
import WelcomeView from '../views/WelcomeView.vue'
import Register from '../views/Register.vue'
import VerifyRegister from '../views/VerifyRegister.vue'
import HomeView from '../views/HomeView.vue'
import Login from '../views/Login.vue'
import LoginAsUser from '../views/LoginAsUser.vue'
import CreateProfile from '../views/CreateProfile.vue'
import Questions from '../views/Questions.vue'
import Questions2 from '../views/Questions2.vue'
import VerifyEmail from '../views/VerifyEmail.vue'
import Panel from '../views/Panel.vue'
import YourePackage from '../views/YourePackage.vue'
import UserMatching from '../views/UserMatching.vue'
import MatchChat from '../views/MatchChat.vue'
import MatchChatSecond from '../views/MatchChatSecond.vue'
import Search from '../views/Search.vue'
import AdminPanel from '../layouts/AdminPanel.vue'
import UserPanel from '../layouts/UserPanel.vue'
import Match from '../views/Match.vue'
import Profile from '../views/Profile.vue'
import MyProfile from '../views/MyProfile.vue'
import ProfileView from '../views/ProfileView.vue'
import ChoseQuestion from '../views/ChoseQuestion.vue'
import Conversation from '../views/Conversation.vue'
import EditProfile from '../views/EditProfile.vue'
import AdminUsers from '../views/AdminUsers.vue'
import AdminBasic from '../views/AdminBasic.vue'
import AdminPlus from '../views/AdminPlus.vue'
import AdminPlusToVerify from '../views/AdminPlusToVerify.vue'
import AdminBasicToVerify from '../views/AdminBasicToVerify.vue'
import AdminPlusAccepted from '../views/AdminPlusAccepted.vue'
import AdminBasicAccepted from '../views/AdminBasicAccepted.vue'
import AdminPlusCancelled from '../views/AdminPlusCancelled.vue'
import AdminBasicCancelled from '../views/AdminBasicCancelled.vue'
import AccountDeleted from '../views/AccountDeleted.vue'
import AdminPlusRejected from '../views/AdminPlusRejected.vue'
import AdminVerify from '../views/AdminVerify.vue'
import AdminVerifyUser from '../views/AdminVerifyUser.vue'
import AdminCheck from '../views/AdminCheck.vue'
import AdminPremium from '../views/AdminPremium.vue'
import AdminInfo from '../views/AdminInfo.vue'
// import AdminPremiumAccounts from '../views/AdminPremiumAccounts.vue'
import SingleUser from '../views/SingleUser.vue'
import SingleUserVerified from '../views/SingleUserVerified.vue'
import SingleUserVerifiedMatch from '../views/SingleUserVerifiedMatch.vue'
import SingleUserVerifyMatchAccepted from '../views/SingleUserVerifyMatchAccepted.vue'
import SingleUserVerifyMatchCanceled from '../views/SingleUserVerifyMatchCanceled.vue'
import SingleUserVerifyMatchRejected from '../views/SingleUserVerifyMatchRejected.vue'
import Settings from '../views/Settings.vue'
import Landing from '../views/Landing.vue'
import Payment from '../views/Payment.vue'
import PaymentPlus from '../views/PaymentPlus.vue'
import PaymentPlusThree from '../views/PaymentPlusThree.vue'
import PaymentSuccess from '../views/PaymentSuccess.vue'
import SaveProfile from '../views/SaveProfile.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import TermsView from '../views/TermsView.vue'
import ThankYouForm from '../views/ThankYouForm.vue'
import PriceList from '../views/PriceList.vue'
import Faqs from '../views/Faqs.vue'
import ResetPassword from '../views/ResetPassword.vue'
import OpeningQuestions from '../views/OpeningQuestions.vue'
import UserSettings from '@/views/UserSettings.vue'

// adminTools
import AdminToolsList from '@/views/AdminToolsList.vue'
import AdminToolsSearch from '@/views/AdminToolsSearch.vue'
import AdminToolsDeleteAccount from '@/views/AdminTools/DeleteAccount.vue'
import AdminToolsSimulate from '@/views/AdminTools/AdminToolsSimulate.vue'
import UpdatePaymentBasicExpirationDate from '@/views/AdminTools/UpdatePaymentBasicExpirationDate.vue'

import TechnicalBreak from '@/views/TechnicalBreak.vue'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import store from '../store'
import ResetPasswordVue from '@/views/ResetPassword.vue'
import AdminSimulatedMatches from '@/views/AdminSimulatedMatches.vue'
import TemporaryDisableUserAccount from '@/views/AdminTools/TemporaryDisableUserAccount.vue'
import ContactPage from '@/views/ContactPage.vue'
import ReinstateAccountAfterTemporaryDisabled from '@/views/AdminTools/ReinstateAccountAfterTemporaryDisabled.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: Landing,
        meta: {}
    },

    // {
    //     path: '/break',
    //     name: 'break',
    //     component: TechnicalBreak,
    //     meta: {}
    // },
    {
        path: '/account-deleted',
        name: 'accdeleted',
        component: AccountDeleted,
        meta: {}
    },
    {
        path: '/zapisz-sie',
        name: 'saveprofile',
        component: SaveProfile,
        meta: {
            requiresAuth: true
        }
    },
    // {
    //     path: '/platform',
    //     name: 'platform',
    //     component: HomeView,
    //     meta: {
    //         showNavigationBar: false
    //     }
    // },
    {
        path: '/register/verify',
        name: 'verifyregister',
        component: VerifyRegister,
        meta: {
            showNavigationBar: false
        }
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        meta: {
            showNavigationBar: false,
            allowUnauthenticated: true
        }
    },
    // {
    //     path: '/settings',
    //     name: 'settings',
    //     component: Settings,
    //     meta: {
    //         showNavigationBar: false
    //     },
    // },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            showNavigationBar: false
        }
    },
    /**
     *
     *  Ten endpoint włączamy wtedy, kiedy chcemy zalogować się jako użytkownik , domkyślnie jest wyłączony
     *
     *
     */
    {
        path: '/login-as-G7jdlY9QIOH2KoL',
        name: 'login-as-user',
        component: LoginAsUser,
        meta: {
            requiresAuth: true,
            isAdmin: true
            // isDevelopment: process.env.VUE_APP_NODE_ENV === 'development'
        }
    },
    // {
    //     path: '/create-profile',
    //     name: 'createprofile',
    //     component: CreateProfile,
    //     meta: {
    //         showNavigationBar: false
    //     },
    // },
    // {
    //     path: '/questions',
    //     name: 'questions',
    //     component: Questions,
    //     meta: {
    //         showNavigationBar: false
    //     },
    // },
    {
        path: '/questions2',
        name: 'questions2',
        component: Questions2,
        meta: {
            showNavigationBar: false,
            requiresAuth: true
        }
    },
    {
        path: '/verify-email',
        component: VerifyEmail,
        meta: {
            showNavigationBar: false,
            requiresAuth: true
        }
    },
    // {
    //     path: '/search',
    //     name: 'search',
    //     component: Search,
    //     meta: {
    //         showNavigationBar: false
    //     },
    // },
    // {
    //     path: '/match',
    //     name: 'match',
    //     component: Match,
    //     meta: {
    //         showNavigationBar: false,
    //         requiresAuth: true
    //     },
    // },
    // {
    //     path: '/profile',
    //     name: 'profile',
    //     component: Profile,
    //     meta: {
    //         showNavigationBar: false
    //     },
    // },
    // {
    //     path: '/profile-view',
    //     name: 'profile-View',
    //     component: ProfileView,
    //     meta: {
    //         showNavigationBar: false
    //     },
    // },
    // {
    //     path: '/chose-question',
    //     name: 'chose question',
    //     component: ChoseQuestion,
    //     meta: {
    //         showNavigationBar: false
    //     },
    // },
    // {
    //     path: '/conversation',
    //     name: 'conversation',
    //     component: Conversation,
    //     meta: {
    //         showNavigationBar: false
    //     },
    // },
    // {
    //     path: '/edit-profile',
    //     name: 'edit profile',
    //     component: EditProfile,
    //     meta: {
    //         showNavigationBar: false
    //     },
    // },
    // {
    //     path: '/admin/verify',
    //     name: 'admin verify',
    //     component: AdminVerify,
    //     meta: {
    //         showNavigationBar: false
    //     },
    // },
    {
        path: '/panel',
        name: 'panel',
        component: UserPanel,
        meta: {
            requiresAuth: true
        },
        redirect: '/panel/notification',
        children: [
            {
                path: 'profile',
                name: 'panel-profile',
                component: MyProfile
            },
            {
                path: 'notification',
                name: 'panel notification',
                component: Panel
            },
            {
                path: 'package',
                name: 'panel-package',
                component: YourePackage
            },
            {
                path: 'match',
                name: 'panel match',
                component: UserMatching
            },

            {
                path: 'chat',
                name: 'panel chat',
                component: MatchChatSecond,
                key: route => route.fullPath
            }
        ]
    },
    {
        path: '/admin',
        component: AdminPanel,
        meta: {
            requiresAuth: true, // Wymaga uwierzytelnienia
            isAdmin: true, // Wymaga bycia administratorem
            showNavigationBar: false
        },
        children: [
            {
                path: 'matches/simulated-matches/:page',
                name: 'matches/simulated-matches',
                component: AdminSimulatedMatches
            },
            {
                path: 'plus/rejected/:id',
                name: 'admin plus user rejected',
                component: AdminPlusRejected
            },
            {
                path: 'plus/cancelled/:id',
                name: 'admin plus user cancelled',
                component: AdminPlusCancelled
            },
            {
                path: 'plus/accepted/:id',
                name: 'admin plus user accepted',
                component: AdminPlusAccepted
            },
            {
                path: 'plus/verify/:id',
                name: 'admin plus user',
                component: AdminPlusToVerify
            },
            {
                path: 'plus/:city/:page',
                name: 'admin plus',
                component: AdminPlus
            },
            {
                path: 'basic/:page',
                name: 'admin basic',
                component: AdminBasic
            },
            {
                path: 'basic/:city/:page',
                name: 'admin_basic_city_page',
                component: AdminBasic
            },
            {
                path: 'basic/verify/:id',
                name: 'admin_basic_verify_page',
                component: AdminBasicToVerify
            },
            {
                path: 'basic/cancelled/:id',
                name: 'admin_basic_user_cancelled',
                component: AdminBasicCancelled
            },
            {
                path: 'basic/accepted/:id',
                name: 'admin_basic_user_accepted',
                component: AdminBasicAccepted
            },
            {
                path: 'users/:city/:page',
                name: 'admin users',
                component: AdminUsers
            },
            {
                path: 'verify/:page',
                name: 'admin verify users',
                component: AdminVerify
            },
            // {
            //     path: 'premium-check/:page',
            //     name: 'admin premium add users',
            //     component: AdminCheck
            // },
            {
                path: 'verify-user/:id',
                name: 'admin verify user',
                component: AdminVerifyUser
            },
            {
                path: 'premium/:page',
                name: 'admin premium users',
                component: AdminPremium
            },
            {
                path: 'users/user/:id',
                name: 'single user',
                component: SingleUserVerified
            },
            {
                path: 'info',
                name: 'info',
                component: AdminInfo
            },
            // {
            //     path: 'premium-accounts',
            //     name: 'premium accounts',
            //     component: AdminPremiumAccounts
            // },
            {
                path: 'users/user/match/:id/:matchId',
                name: 'single user match',
                component: SingleUserVerifiedMatch
            },
            {
                path: 'users/user/match/accepted/:id',
                name: 'single user match accepted',
                component: SingleUserVerifyMatchAccepted
            },
            {
                path: 'users/user/match/canceled/:id',
                name: 'single user match canceled',
                component: SingleUserVerifyMatchCanceled
            },
            {
                path: 'users/user/match/rejected/:id',
                name: 'single user match rejected',
                component: SingleUserVerifyMatchRejected
            },
            {
                path: 'verify/user/:id',
                name: 'single user verify',
                component: SingleUser
            },
            {
                path: 'tools',
                name: 'admin-tools',
                component: AdminToolsList
            },
            {
                path: 'tools/temporary-disable-user-account',
                name: 'temporary-disable-user-account',
                component: TemporaryDisableUserAccount
            },
            {
                path: 'tools/reinstance-account-after-temporary-disabled',
                name: 'reinstance-account-after-temporary-disabled',
                component: ReinstateAccountAfterTemporaryDisabled
            },
            {
                path: 'tools/search',
                name: 'admin-tools-search',
                component: AdminToolsSearch
            },
            {
                path: 'tools/delete-account',
                name: 'admin-tools-delete-account',
                component: AdminToolsDeleteAccount
            },
            {
                path: 'tools/simulate',
                name: 'admin-tools-simulate',
                component: AdminToolsSimulate
            },
            {
                path: 'tools/update-payment-basic-expiration-date',
                name: 'admin-tools-update-payment-basic-expiration-date',
                component: UpdatePaymentBasicExpirationDate
            }
        ]
    },
    {
        path: '/payment',
        component: Payment,
        meta: {
            requiresAuth: true, // Wymaga uwierzytelnienia
            showNavigationBar: false
        }
    },
    {
        path: '/payment-plus',
        component: PaymentPlus,
        meta: {
            requiresAuth: true, // Wymaga uwierzytelnienia
            showNavigationBar: false
        }
    },
    {
        path: '/payment-plus-3',
        component: PaymentPlusThree,
        meta: {
            requiresAuth: true, // Wymaga uwierzytelnienia
            showNavigationBar: false
        }
    },
    {
        path: '/payment/success',
        name: 'payment success',
        component: PaymentSuccess,
        meta: {
            requiresAuth: true, // Wymaga uwierzytelnienia
            showNavigationBar: false
        }
    },
    {
        path: '/polityka-prywatnosci',
        name: 'polityka prywatności',
        component: PrivacyPolicy,
        meta: {
            showNavigationBar: false
        }
    },
    {
        path: '/regulamin',
        name: 'regulamin',
        component: TermsView,
        meta: {
            showNavigationBar: false
        }
    },
    {
        path: '/dziekujemy',
        name: 'dziekujemy',
        component: ThankYouForm,
        meta: {
            showNavigationBar: true
        }
    },
    {
        path: '/cennik',
        name: 'cennik',
        component: PriceList,
        meta: {
            showNavigationBar: false
        }
    },
    {
        path: '/kontakt',
        name: 'kontakt',
        component: ContactPage,
        meta: {
            showNavigationBar: false
        }
    },
    {
        path: '/pytania',
        name: 'pytania',
        component: Faqs,
        meta: {
            showNavigationBar: false
        }
    },
    {
        path: '/password-reset',
        name: 'password-reset',
        component: ResetPassword,
        meta: {
            showNavigationBar: false
        }
    },
    {
        path: '/opening-questions',
        name: 'opening-questions',
        component: OpeningQuestions,
        meta: {
            showNavigationBar: false
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
                top: 0
            }
        }
        return { top: 0 }
    }
})

function checkIfAdmin() {
    const authToken = getAuthCookie()
    if (!authToken) return false

    try {
        const base64Url = authToken.split('.')[1] // Pobierz payload tokenu JWT
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/') // Zastąp '-' na '+' oraz '_' na '/'
        const payload = JSON.parse(window.atob(base64)) // Dekoduj Base64 i zinterpretuj jako JSON

        return payload.role === 'admin'
    } catch (error) {
        console.error('Error decoding token:', error)
        return false
    }
}

router.beforeEach(async (to, from, next) => {
    // Ustawianie tytułu strony
    if (to.meta && to.meta.title) {
        document.title = to.meta.title
    }

    // Sprawdzenie czy trasa wymaga uwierzytelnienia
    if (to.matched.some(record => record.meta.requiresAuth)) {
        const authToken = getAuthCookie()

        if (!authToken) {
            next({ path: '/login', query: { redirect: to.fullPath } })
            return
        }

        // console.log(authToken)

        const isValidToken = await verifyAuthToken(authToken)
        // console.log(isValidToken)

        if (!isValidToken) {
            console.log('Invalid token, redirecting to /login')
            next({ path: '/login', query: { redirect: to.fullPath } })
            return
        }

        store.commit('setAuth', true)

        const isAdmin = checkIfAdmin()
        // console.log(`Is Admin: ${isAdmin}`)

        if (to.matched.some(record => record.meta.isAdmin) && !isAdmin) {
            next({ path: '/' })
            return
        }

        next()
    } else if (to.matched.some(record => record.meta.allowUnauthenticated)) {
        next()
    } else {
        next()
    }
})

function getAuthCookie() {
    const name = 'authToken='
    const decodedCookie = decodeURIComponent(document.cookie)
    const cookieArray = decodedCookie.split(';')

    for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i].trim()
        if (cookie.startsWith(name)) {
            return cookie.substring(name.length)
        }
    }
    return null
}

async function logout() {
    const apiUrl = process.env.VUE_APP_API
    await axios.post(`${apiUrl}api/logout`)

    document.cookie =
        'authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'

    location.reload()
}

async function verifyAuthToken(token) {
    const apiUrl = process.env.VUE_APP_API // Używanie zmiennej środowiskowej
    // console.log('apiUrl:', apiUrl)
    try {
        const response = await axios.post(`${apiUrl}api/login/verify-token`, {
            token
        })
        // console.log('Response from verify-token:', response.data)

        return response.data.isValid
    } catch (error) {
        logout()
        console.error('Error verifying token:', error)
        return false
    }
}

export default router

<template>
    <div class="search-user">
        <div class="search-user__header">
            <h1 class="text-4xl font-medium">Wyszukaj użytkownika</h1>
        </div>
        <div class="search-user__form">
            <form @submit.prevent="handleSearch">
                <label for="userData">
                    <input
                        id="userData"
                        type="text"
                        v-model="userData"
                        placeholder="Adres email lub ID:"
                        class="login__form-userData"
                    />
                </label>

                <div class="search-user__form-bottom">
                    <button
                        :class="`min-w-[210px] h-[50px] flex items-center justify-center p-4 ${
                            pending || !userData
                                ? 'cursor-not-allowed disabled pointer-events-none'
                                : 'cursor-pointer'
                        }`"
                    >
                        <span v-if="!pending">Wyszukaj</span>
                        <span class="block scale-75 origin-center" v-else>
                            <Loader />
                        </span>
                    </button>
                </div>

                <div v-if="user" class="search-user__form-response mt-10">
                    <UserHorzontalCard :user="user" />
                </div>

                <div class="mt-10" v-if="error">{{ error }}</div>
            </form>
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue'
    import axios from 'axios'
    import Loader from '@/components/atoms/Loader.vue'
    import UserHorzontalCard from '@/components/user/UserHorzontalCard.vue'

    const userData = ref('')
    const user = ref('')
    const error = ref(false)
    const pending = ref(false)
    const API_URL = process.env.VUE_APP_API

    const getCookie = name => {
        const value = `; ${document.cookie}`
        const parts = value.split(`; ${name}=`)
        if (parts.length === 2) {
            return parts.pop().split(';').shift()
        }
    }

    const handleSearch = async () => {
        const authToken = getCookie('authToken')
        const config = {
            headers: { Authorization: `Bearer ${authToken}` }
        }

        pending.value = true
        user.value = ''
        error.value = ''

        try {
            const response = await axios.post(
                `${API_URL}api/users/search-user`,
                {
                    userData: userData.value
                },
                config
            )

            user.value = response.data
        } catch (e) {
            if (e.response) {
                switch (e.response.status) {
                    case 404:
                        error.value =
                            e.response.data.message ||
                            'Nie znaleziono użytkownika'
                        break
                    case 500:
                        error.value = 'Błąd serwera. Spróbuj ponownie później.'
                        break
                    default:
                        error.value =
                            e.response.data.message ||
                            'Wystąpił nieoczekiwany błąd.'
                }
            } else if (e.request) {
                error.value =
                    'Brak odpowiedzi z serwera. Sprawdź połączenie internetowe.'
            } else {
                error.value = 'Błąd podczas wysyłania żądania.'
            }
        } finally {
            pending.value = false
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/breakpoints.scss';
    @import '@/assets/scss/containers.scss';
    @import '@/assets/scss/global.scss';

    .search-user {
        &__header {
            margin-bottom: 20px;
        }
        &__form {
            display: flex;
            flex-direction: column;
            max-width: 1075px;
            padding-top: 40px;

            input {
                padding: 27px 25px;
                color: $black;
                font-size: 16px;
                line-height: 30px;
                border-radius: 0;
                font-family: 'Open Sans', sans-serif;
                outline: none;
                border: 1px solid $black;
                background-color: transparent;
                width: 100%;
                @include breakpoint-max('xs-tablet') {
                    padding: 17px 15px;
                }
                @include breakpoint-max('mobile') {
                    padding-top: 53px;
                    padding-left: 185px !important;
                }
            }

            button {
                outline: none;
                font-size: 2.4rem;
                background-color: $pink;
                line-height: 3rem;
                color: $white;
                text-transform: uppercase;
                font-family: 'Open Sans', sans-serif;
                font-weight: 700;
                padding: 10px 40px;
                margin-top: 20px;
                cursor: pointer;
                opacity: 1;
                transition: all 0.3s ease-in;

                &:hover {
                    opacity: 0.7;
                }
            }
            label {
                display: block;
                position: relative;
                p {
                    font-weight: 700;
                    color: $black;
                    font-size: 16px;
                    line-height: 30px;
                    font-family: 'Open Sans', sans-serif;
                    position: absolute;
                    top: 51%;
                    transform: translateY(-50%);
                    left: 27px;
                    @include breakpoint-max('mobile') {
                        top: 20px;
                        transform: translateY(0%);
                        left: 28px;
                    }
                }
            }

            &-response {
                p {
                    font-size: 1.6rem;
                    color: $black;
                    margin-top: 20px;
                }
            }
        }
    }
</style>

<template>
    <div class="prod-warning">
        <div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="icon icon-tabler icons-tabler-outline icon-tabler-alert-square"
            >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path
                    d="M3 5a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-14z"
                />
                <path d="M12 8v4" />
                <path d="M12 16h.01" />
            </svg>
        </div>
        <div>UWAGA jesteś połączony z produkcyjną bazą danych</div>
    </div>
</template>

<script setup></script>

<style lang="scss" scoped>
    .prod-warning {
        position: fixed;
        bottom: 0px;
        left: 0px;
        height: 40px;
        width: 100vw;
        background-color: #d13724;
        color: #fff;
        z-index: 99999;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        gap: 10px;
    }
</style>
